@mixin regu-font {
    font-family: Sofia-Regular;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
}
@mixin regular-font {
    @include regu-font;
    font-size: 14px;
}
@mixin bold-font {
    font-family: Sofia-Bold;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
}

@mixin brand-name {
    font-family: Sofia-Regular;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 140%;
    color: $main-dark-blue;
    text-align: center;
}
@mixin input-font {
    @include regular-font;
    color: $light-black;
    cursor: text;
}
@mixin input-placeholder {
    @include regular-font;
    color: $light-medium-b2;
    text-transform: capitalize;
}

@mixin flex-center {
    display: flex;
    align-items: center;
}

.text-capitalize {
    text-transform: capitalize;
}

.mb-0 {
    margin-bottom: 0px !important;
}
.mb-12 {
    margin-bottom: 12px;
}

.position-absolute {
    position: absolute;
}

.top--2px{
    top: -2px;
}

.fix-sofia-baseline {
    position: relative;
    top: 1.4px;
}

.default-bold-font {
    font-family: 'Sofia-Bold';
    font-weight: 700;
    font-size: 14px;
}
