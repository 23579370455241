.blog {
    @include flex-center;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    padding-top: 64px;
    padding-bottom: 64px;
    .subtitle {
        text-align: center;
    }
    .blog-items {
        display: flex;
        justify-content: center;
        gap: 24px;
        flex-wrap: wrap;
        width: 100%;
        margin-top: 24px;
        @media screen and (min-width: 768px) {
            margin-top: 33px;
        }
        .blog-item {
            @media screen and (min-width: 768px) {
                width: calc((100% - (24px * 3)) / 2);
            }
            @media screen and (min-width: 1024px) {
                width: calc((100% - (24px * 3)) / 4);
            }
            &.blog-item:hover {
                filter: drop-shadow(0px 10px 20px rgba(23, 90, 220, 0.1));
            }
        }
    }
    .bottom-btn {
        margin-top: 32px;
    }
    .big-font {
        @media screen and (min-width: 1200px) {
            font-size: 20px;
        }
    }
}
.blog-card {
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    min-height: 365px;
    background-color: $white;
    .blog-card-image {
        width: 100%;
        height: 148px;
        border-radius: 5px;
        img {
            border-radius: 5px;
        }
    }
    .blog-body {
        padding: 16px;
        background: #ffffff;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        .blog-title {
            font-family: 'Sofia-Bold';
            font-style: normal;
            font-size: 20px;
            line-height: 140%;
            display: flex;
            color: $medium-black;
            min-height: 56px;
        }
        .blog-title.active {
            text-decoration-line: underline;
            color: $main-dark-blue;
        }
        .blog-content {
            @include regular-font;
            color: #727272;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
            min-height: 36.4px;
        }
        .profile-container {
            .profile-picture {
                width: 32px;
                height: 32px;
                border-radius: 50%;
                overflow: hidden;
            }
            .user-name {
                @include regular-font;
                color: $dark-gray;
            }
            .author-name {
                @include regular-font;
                color: $medium-black;
                text-transform: capitalize;
                margin-bottom: 6px;
            }
        }
    }
}
.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    font-size: 18px;
    background: #fff;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
