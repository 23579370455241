.full-section {
    background-color: #ffffff;
    padding-top: 64px;
    padding-bottom: 64px;

    .why-us-section-container {
        .why-us-section {
            gap: 16px;
            display: flex;
            flex-wrap: wrap;
        }
    
        .location-container-2 {
            .container {
                height: 400px;
                .picture {
                    .img-fluids {
                        height: 276px;
                    }
                    &:hover {
                        height: 312px;
                    }
                }
            }
        }
    
        .container {
            flex: 1 1 calc(25% - 20px); /* Four containers, adjusting for gap */
            border-radius: 5px;
            border: 1px solid #e0e0e0;
            font-family: 'Sofia Pro', sans-serif;
            font-size: 16px;
            height: 324px;
            overflow: hidden;
    
            .picture {
                position: relative;
                width: 100%;
                height: auto;
                object-fit: cover;
    
                .img-fluids {
                    border-radius: 5px;
                    max-width: 100%;
                    height: 200px;
                }
    
                &:hover {
                    // Default height for three items
                    height: 236px;
                    transition: height 0.5s ease-in-out;
                    .overlay {
                        opacity: 1;
                        transition: opacity 0.5s ease-in-out;
                    }
                    ~ .location {
                        .location-details {
                            .location-address {
                                display: none;
                                transition: display 0.5s ease-in-out;
                            }
                        }
                    }
                }
            }
    
            .overlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.7); /* Dark overlay for contrast */
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                opacity: 0;
                transition: opacity 0.3s ease;
    
                .static-image {
                    width: 100%;
                    height: 100%;
                }
    
                .location-url {
                    display: flex;
                    background-color: blue;
                    align-items: center;
                    justify-content: center;
                    border-radius: 5px;
                    position: absolute;
                    color: white;
                    text-decoration: none;
                    font-family: 'Sofia-Regular';
                    font-size: 14px;
                    padding: 8px 16px;
                    font-weight: 700;
                }
            }
    
            .location {
                display: flex;
                flex-direction: column;
                align-items: baseline;
                gap: 16px;
                font-family: 'Sofia-Regular';
                font-weight: 400;
                line-height: 22px;
                padding: 16px;
    
                .title {
                    line-height: 16px;
                    font-size: 16px;
                    font-weight: 700;
                    color: #091731;
                }
    
                .location-details {
                    display: flex;
                    flex-direction: column;
                    gap: 12px;
    
                    span {
                        color: #595959;
                        gap: 8px;
                        white-space: nowrap;
                        display: flex;
    
                        svg {
                            flex-shrink: 0; /* Prevent the icon from shrinking */
                        }
                    }
                }
            }
        }
    
        @media (max-width: 768px) {
            .container {
                flex: 1 1 calc(50% - 20px); /* Two containers per row on smaller screens */
            }
            .location {
                padding: 16px 10px !important;
                .location-details {
                    span {
                        gap: 4px !important;
                        font-size: 14px;
                    }
                }
            }
        }
    
        @media (max-width: 480px) {
            .container {
                flex: 1 1 100%; /* One container per row on very small screens */
            }
        }
    }

    .location-container {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .location-header {
            width: 37%;
            @media screen and (max-width: 768px) {
                width: 100%;
            }
        }
        .location {
            width: 60%;
            @media screen and (max-width: 768px) {
                width: 100%;
            }
            .location-card {
                .location-card-image {
                    position: relative;
                    &:hover{
                        .overlay {
                            opacity: 1;
                            transition: all 0.5s ease-in-out;
                        }
                    }

                    .overlay {
                        position: absolute;
                        top: 0;
                        opacity: 0;
                        transition: all 0.5s ease-in-out;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .location-url {
                            align-items: center;
                            background-color: blue;
                            border-radius: 5px;
                            color: #fff;
                            display: flex;
                            font-family: 'Sofia-Regular';
                            font-size: 14px;
                            font-weight: 700;
                            justify-content: center;
                            padding: 8px 16px;
                            position: absolute;
                            text-decoration: none;
                        }
                    }
                }
                .location-card-content {
                    height: 100%;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    background: #ffffff;
                    border-radius: 5px;
                    padding: 24px;
                }
            }
        }
        @media screen and (max-width: 768px) {
            flex-direction: column;
            gap: 24px;
        }
    }
}
