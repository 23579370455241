.interested {
    margin-block: 45px 80px;
    .sec-seperator {
        margin-inline: 0;
    }
    .title {
        margin-bottom: 8px;
        text-align: left;
        @media screen and (min-width: 768px) {
            font-size: 25px;
        }
    }
    .subtitle {
        text-align: left;
        width: 100%;
    }
    .interest-btn {
        margin-inline: auto;
        margin-top: 40px;
        display: grid;
        place-items: center;
    }
}

.interested-padding {
    padding: 0 15px;
    @media screen and (min-width: 768px) {
        padding: 0 30px;
    }
    @media screen and (min-width: 1200px) {
        padding: 0 170px;
    }
    @media screen and (min-width: 1441px) {
        padding: 0;
        max-width: 1100px;
        margin-inline: auto;
    }
}
.interested-card {
    background-color: $light-white;
    border-radius: 5px;
    padding: 16px;
    cursor: pointer;
    border: 2px solid transparent;
    @media screen and (min-width: 1024px) {
        padding: 32px;
    }
    .interested-card__top {
        @include flex-center;
        justify-content: space-between;
        .interested-card__title {
            @include flex-center;
            gap: 10px;
            margin-bottom: 8px;
            @media screen and (min-width: 768px) {
                margin-bottom: 16px;
            }
            .interested-card-title {
                font-family: Sofia-Bold;
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 140%;
                color: $main-dark-blue;
                @media screen and (min-width: 768px) {
                    font-size: 20px;
                }
            }
        }
    }
    p {
        font-family: Sofia-Regular;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 140%;
        color: $medium-black;
        @media screen and (min-width: 768px) {
            font-size: 16px;
        }
    }
    &:hover {
        border: 2px solid $main-blue;
    }
}
.interested-card:not(:last-child) {
    margin-bottom: 24px;
}
.back-to-card {
    background-color: $light-white;
    border-radius: 5px;
    padding: 5px 16px;
    cursor: pointer;
    border: 2px solid transparent;
    margin-top: 24px;
    @include flex-center;
    width: 100%;
    font-family: Sofia-Regular;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 140%;
    color: $medium-black;
    gap: 10px;
    @media screen and (min-width: 768px) {
        font-size: 16px;
    }
    @media screen and (min-width: 1023px) {
        padding: 10px 32px;
    }
    &:hover {
        color: $main-blue;
    }
}
.car-card {
    padding: 16px;
    border-radius: 5px;
    margin-block: 24px;
    background-color: $light-white;
    @media screen and (min-width: 768px) {
        display: flex;
        justify-content: space-between;
    }
    @media screen and (min-width: 1024px) {
        padding: 32px;
    }
    .car-card__left {
        margin-bottom: 16px;
        @media screen and (min-width: 768px) {
            min-width: max-content;
            display: flex;
            gap: 12px;
            margin-bottom: 0;
        }
        @media screen and (min-width: 1024px) {
            gap: 24px;
        }
        .car-image {
            width: 100%;
            height: 155px;
            border-radius: 5px;
            overflow: hidden;
            margin-bottom: 16px;
            cursor: pointer;
            @media screen and (min-width: 768px) {
                width: 130px;
                height: 65px;
                margin-bottom: 0;
            }
        }
        .car-deets {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 5px;
            @media screen and (min-width: 768px) {
                padding-left: 12px;
                border-left: 1px solid $medium-grey;
                gap: 13px;
            }
            @media screen and (min-width: 1024px) {
                padding-left: 24px;
            }
            .car-name {
                font-family: 'Sofia-Regular';
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 140%;
                color: $main-dark-blue;
                cursor: pointer;
                @media screen and (min-width: 768px) {
                    font-size: 20px;
                }
            }
            .car-features {
                @include flex-center;
                flex-wrap: wrap;
                min-width: max-content;
                .features-container {
                    @include flex-center;
                    gap: 5px;
                    padding-right: 5px;
                    font-family: 'Sofia-Regular';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 14px;
                    color: #595959;
                    text-transform: capitalize;
                    @media screen and (min-width: 768px) {
                    }
                    @media screen and (min-width: 1024px) {
                        gap: 10px;
                        padding-right: 16px;
                    }
                    .features-icons {
                        width: 17px;
                        height: 17px;
                    }
                }
                .features-container:not(:last-child) {
                    border-right: 1px solid #b3b3b3;
                }
                .features-container:not(:first-child) {
                    padding-left: 5px;

                    @media screen and (min-width: 1024px) {
                        padding-left: 10px;
                    }
                }
            }
        }
    }
    .car-card__right {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        @media screen and (min-width: 768px) {
            text-align: right;
        }
        h4 {
            font-family: 'Sofia-Regular';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 140%;
            color: $main-blue;
            @media screen and (min-width: 768px) {
                margin-bottom: 4px;
                font-size: 25px;
            }
        }
        p {
            font-family: Sofia-Regular;
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 140%;
            color: $dark-blue-tint1;
            // text-align: right;
            @media screen and (min-width: 768px) {
                font-size: 16px;
            }
        }
    }
}
.interested-modal {
    @media screen and (min-width: 768px) {
        width: 590px !important;
        max-width: unset !important;
    }
    h3 {
        font-family: 'Sofia-Regular';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 140%;
        color: $main-dark-blue;
        margin-bottom: 3px;
    }
    .subtitle {
        margin-inline: 0;
        text-align: left !important;
        margin-bottom: 24px;
    }
    .profile-input {
        @include flex-center;
        gap: 10px;
        padding: 13px 12px;
        border: 1px solid $medium-grey;
        border-radius: 5px;
        background: $extra-white;
        height: 50px;
        margin-bottom: 16px;
        .icon {
            margin-top: 3px;
        }
        input {
            outline: none;
            background-color: $extra-white;
            width: 100%;
            border: none;
            @include input-font;
            color: $dark-gray;
        }
        input::placeholder {
            @include input-font;
            color: $light-medium-b2;
            background-color: $extra-white;
        }
    }
    .bottom-section {
        @include flex-center;
        margin-top: 24px;
        p {
            font-family: 'Sofia-Regular';
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 140%;
            color: $light-black;
        }
    }
    .buy-submit {
        @include flex-center;
        justify-content: space-between;
        margin-top: 24px;
    }
}
