.promotions-container {
    background-color: $light-white;
    padding: 64px 0;
    .promotions {
        @include flex-center;
        flex-direction: column;
        margin: auto;
        @media screen and (min-width: 1024px) {
            flex-direction: row;
        }
        .promotions-left {
            margin-right: 12px;
            // margin-bottom: 24px;
            @media screen and (min-width: 1024px) {
                width: 50%;
                max-width: 295px;
            }
            .title {
                white-space: normal;
                word-wrap: break-word;
                margin-bottom: 8px;
                font-size: 20px;
            }
            .subtitle {
                margin-bottom: 24px;
            }
            &-imageContainer {
                width: 100%;
                height: 75px;
                overflow: hidden;
                margin-top: 32px;
                @media screen and (max-width: 768px) {
                    margin-bottom: 40px;
                }
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: left;
                cursor: pointer;

                @media screen and (max-width: 768px) {
                    object-position: center;
                }
            }
        }
        .promotions-right {
            display: flex;
            overflow-x: hidden;
            width: 100%;
            .swiper-slide {
                background-color: $light-white;
                .carousel-item {
                    .carousel-image {
                        width: 100%;
                        border-radius: 5px;
                        margin-bottom: 12px;
                        height: 44vw;
                        @media screen and (min-width: 768px) {
                            height: 30vw;
                        }
                        @media screen and (min-width: 1024px) {
                            height: 12.5vw;
                        }
                        img {
                            object-fit: cover;
                            border-radius: 5px;
                        }
                    }
                    .carousel-title {
                        min-height: 45px;
                        @include regular-font;
                        color: #091731;
                        b {
                            font-weight: 700;
                        }
                    }
                    .user-member {
                        .user-info {
                            display: flex;
                            flex-direction: column;
                            padding-left: 12px;
                            span {
                                font-family: 'Sofia-Regular';
                            }
                        }
                    }
                }
            }
        }
        .promotions-right::-webkit-scrollbar {
            display: none;
        }
    }
}
