body {
    overflow-x: hidden;
}
.lg-backdrop {
    z-index: 1049 !important;
}
.detail-page {
    background-color: $white;
    .loading-detail {
        height: 20vh;
    }
    .hide-logo {
        @media screen and (max-width: 1023px) {
            .navbar-right {
                display: unset;
                right: 15px;
                top: calc(50% - (44px / 2));
            }
        }
    }
    .title {
        margin-bottom: 24px;
    }
    .process-container {
        background-color: $light-white;
        margin-top: 30px;
        padding-bottom: 1px;
    }
    .questionnaire-container {
        background-color: $white;
    }
    .details-certi {
        background-color: $white;
    }
    .loan-simulator-wrapper {
        background-color: $light-white;
    }
}
.finance {
    .questionnaire-container {
        background-color: $light-white;
    }
    .padding-15 {
        padding: 15px;
    }
}
.about {
    .process-container {
        background-color: $light-white;
        margin-bottom: 0;
        padding: 60px 0 43px;
    }
    .questionnaire-container {
        background-color: $light-white;
    }
}
.discover {
    padding-block: 64px !important;
    .center {
        @media screen and (min-width: 768px) {
            margin-inline: auto;
        }
    }
    .title {
        text-align: center;
        margin-bottom: 8px;
    }
    .discover-content {
        @include regular-font;
        text-align: center;
        color: $medium-black;
    }
}
.additional {
    .additional-photos {
        @include flex-center;
        justify-content: center;
        gap: 24px;
        .additional-content {
            p {
                @include regular-font;
                text-align: center;
                color: $medium-black;
            }
            .image-container {
                height: 175px;
                img {
                    object-fit: cover;
                }
            }
        }
    }
}

.collapsed {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    // filter: blur(4px); /* Adjust the blur intensity as needed */
    transition: filter 0.3s ease-in-out;
    position: relative;

    color: #454545 !important;
    font-family: Sofia-Regular;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    text-align: center;
}

.collapsed::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 26px; /* Height of the blurred section */
    background: inherit;
    backdrop-filter: blur(1px);
    transition: filter 0.3s ease-in-out;
}

.expanded {
    position: relative;
    overflow: visible;
    filter: none;
    transition: filter 0.3s ease-in-out;

    color: #454545 !important;
    font-family: Sofia-Regular;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    text-align: center;
}
.showDesc {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    background-color: #175adc;
    color: #fff;
    padding: 10px 16px;
    border-radius: 4px;
}
.ant-collapse {
    background-color: inherit !important;
}
