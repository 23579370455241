.bread-crumbs {
  margin-bottom: 24px;
  ol {
    align-items: center;
    gap: 6px;
  }
  .ant-breadcrumb li:last-child {
    color: unset;
  }
  .breadcrumb-seperator {
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background-color: $dark-gray;
  }
  .ant-breadcrumb-link {
    font-family: 'Sofia-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 140%;
    text-align: center;
    color: $light-black;
  }
}
