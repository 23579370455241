.chooseAuto24 {
    text-shadow: none !important;
    &-container {
        width: 90%;
        margin: auto;
        padding-top: 20px;
        padding-top: 64px;
        padding-bottom: 64px;
        @include flex-center;
        flex-direction: column;
        @media screen and (min-width: 768px) {
            width: 80%;
        }
        .title {
            margin-bottom: 40px;
        }
    }
    &-content {
        width: 100%;
        height: max-content;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 30px;
        flex-wrap: wrap;
        font-family: Sofia-Regular;
        &-item {
            background-color: #C3E5FF;
            width: 277.5px;
            align-self: stretch;
            padding: 40px 18px;
            border-radius: 5px;
            text-align: center;

            &-title {
                font-size: 16px;
                font-style: normal;
                line-height: 140%;
                font-weight: 700;
                color: #091731;
            }
            &-subtitle {
                font-size: 16px;
                font-style: normal;
                line-height: 140%;
                font-weight: 400;
                color: #1F335A;
            }
            &-imageContainer {
                height: 104px;
                border-radius: 5px;
                & > img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    border-radius: 5px;
                }
                margin-bottom: 16px;
            }
        }
    }
}
