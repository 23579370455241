.phoneNumberInput {
    font-family: 'Sofia-Regular';
    .intl-tel-input {
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        & > .flag-container {
            min-height: 48px;
            & > .selected-flag {
                // min-width: 109px !important;
                min-width: 120px !important;
                height: 100%;
                display: flex !important;
                align-items: center;
                padding-left: 11px;
                padding-right: 11px;
            }
        }
        & > input {
            width: 100%;
            font-family: 'Sofia-Regular';
            font-size: 16px;
            color: #595959;
            flex-grow: 1;
            padding: 4px 11px;
            // padding-left: 120px;
            padding-left: 130px;
            min-height: 48px;
            border: 1px solid #b3b3b3;
            border-radius: 5px;
            outline: none;
            background-color: #F5F8FF;
            // &:focus {
            //     border-color: #1677ff;
            // }
            &::placeholder {
                color: $light-medium-b2;
            }
        }
        & > .form-control {
            // padding-left: 120px !important;
            padding-left: 130px !important;
        }
        .selected-dial-code {
            // padding-left: 18px !important;
            padding-left: 0 !important;
            // margin-left: 18px;
            margin-left: 8px;
            margin-right: 1px;
            font-family: 'Sofia-Regular';
            font-size: 16px;
            color: #595959;
        }
        .arrow {
            display: none;
        }
        .arrowElement {
            &::after {
                content: '' !important;
                position: absolute;
                top: 33%;
                margin-left: 5px;
                border-left: 2px solid #B3B3B3;
                border-bottom: 2px solid #B3B3B3;
                height: 10px;
                width: 10px;
                transform: rotate(-45deg);
            }
        }
        .country-list {
            z-index: 10;
            position: relative;
            max-height: 165px;
        }
    }

    &-error {
        .intl-tel-input > input {
            border-color: #ff4d4f;
        }
        .intl-tel-input > input:focus {
            border-color: #ff4d4f;
        }
    }
    &-errorField {
        & > span {
            color: #ff4d4f;
            font-size: 15px !important;
            font-family: 'Sofia-Regular';
            margin-left: 3px;
            line-height: 23.6px !important;
        }
    }
    .ant-form-item {
        margin: 0;
    }
    .ant-form-item-control {
        min-width: 100% !important;
    }
}
