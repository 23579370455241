.mySwiper {
    .swiper-button-prev,
    .swiper-button-next {
        display: none;
    }

    @media screen and (min-width: 1024px) {
        .swiper-button-prev,
        .swiper-button-next {
            display: flex !important;
            align-items: center;
            color: #454545;
            background-color: #fff;
            border-radius: 20px;
            font-size: 10px !important;
            height: 40px;
            width: 40px;
            box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.12);
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .swiper-button-next:after,
    .swiper-button-prev:after {
        font-size: 16px;
        font-weight: 700;
    }

    .swiper-button-prev.swiper-button-disabled,
    .swiper-button-next.swiper-button-disabled {
        display: none !important;
    }
}
