.listing-card {
    background-color: $main-blue;
    border-radius: 5px;
    padding: 32px;
    height: 100%;
    position: relative;
    min-height: 370px;
    &.red {
        background-color: #091731;
    }
    &.white {
        background-color: $main-dark-blue;
    }
    &:hover {
        filter: drop-shadow(0px 10px 20px rgba(23, 90, 220, 0.1));
    }
    .card-title {
        font-family: 'Sofia-Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 140%;
        margin-bottom: 8px;
        color: $white;
    }
    .loan-btn {
        &.dark-blue {
            &:hover {
                background: $main-dark-blue;
                opacity: 0.4;
                box-shadow: 0px 5px 10px rgba(255, 255, 255, 0.5);
            }
        }
        &.white {
            background-color: $white;
            color: $main-dark-blue;
            padding-inline: 36px;
            &:hover {
                box-shadow: 0px 5px 10px rgba(255, 255, 255, 0.5);
            }
        }
    }
    .card-content {
        font-family: 'Sofia-Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 140%;
        color: $light-white;
        margin-bottom: 16px;
    }
    .listing-image {
        position: absolute;
        bottom: 0;
        right: -20px;
        height: 120px;
        // @media screen and (min-width: 1024px) {
        // }
        @media screen and (min-width: 1200px) {
            right: -30px;
            height: 175px;
        }
    }
}
