.ant-select-selection-item{
  font-size: 14px !important;
  font-weight: 400;
  text-transform: uppercase;
}
.ant-select-selector{
  display: flex !important;
  align-items: center !important;
  gap: 6px;
}
.ant-select-arrow{
  font-weight: 400;
  color: white !important;
}

.ant-select-dropdown{
    width: 70px!important;
    font-weight: 400;
    font-size: 14px;
    text-transform: uppercase;
}

.active {
  .display-contents{
    .ant-select{
      .ant-select-selector{
        .ant-select-selection-item{
          color: black !important;
        }
      }
      .ant-select-arrow{
        color: black !important;
      }
    }
  }
}
