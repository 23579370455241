.follow-instagram {
    margin-block: 40px;
    .title {
        text-align: center;
        margin-bottom: 8px;
    }
    .subtitle {
        text-align: center;
        margin-inline: auto;
    }
    .follow-sep {
        margin-inline: auto;
    }
    .insta-container {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        flex-direction: column;
        margin-top: 24px;
        justify-content: center;
        @media screen and (min-width: 600px) {
            flex-direction: row;
        }

        @media screen and (min-width: 1200px) {
            justify-content: space-between;
        }
        .image-container {
            width: 100%;
            height: 200px;
            cursor: pointer;
            position: relative;
            @media screen and (min-width: 600px) {
                width: calc((100% - 32px) / 3);
            }
            @media screen and (min-width: 1200px) {
                width: 24%;
            }
            .on-hover {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                color: transparent;
                @include flex-center;
                justify-content: center;
                text-align: center;
                transition: all 0.5s ease-in;
            }
            .on-hover:hover {
                background-color: $main-blue;
                color: $white;
                opacity: 0.5;
            }
        }
        .powr-instagram-feed {
            display: flex;
            flex-wrap: wrap;
            gap: 16px;
            flex-direction: column;
            margin-top: 24px;
            justify-content: center;
            @media screen and (min-width: 600px) {
                flex-direction: row;
            }

            @media screen and (min-width: 1200px) {
                justify-content: space-between;
            }
        }
    }
    .follow-btn {
        border: 1px solid blue;
        background-color: $white;
        margin-inline: auto;
        margin-top: 32px;
        color: #175adc;
        font-weight: 700;

        &:hover {
            background: #dee6f7;
            border: 1px solid #175adc;
        }
        .default-font{
            // margin-top: 5px;
        }
    }
}
