.rating {
  display: inline-flex;
  font-size: 50px;
}

.star-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.half-star {
  width: 16px;
  height: 16px;
  fill: gold;
}

.star {
  width: 16px;
  height: 16px;
  fill: lightgray;
  margin-right: 5px;
  position: relative;

  &.full {
    background-color: gold;
  }

  &.half {
    background: linear-gradient(90deg, gold 50%, lightgray 50%);
  }

  &.empty {
    background-color: lightgray;
  }
}

.flex-column {
  margin-left: 15px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.review-source {
  font-weight: 700;
}

.rating-text {
  color: gold;
  font-size: 14px;
  font-weight: 700;
  padding: 2px;
  margin-right: 10px;
}

.facebook-rating-text {
  color: blue;
  font-size: 14px;
  font-weight: 700;
  padding: 2px;
  margin-right: 10px;
}

.rating-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;

  .rating-source{
    font-family: 'Sofia-Regular';
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 32px;
  }

  @media screen and (min-width: 1200px) {
    align-items: flex-start;
  }
}
