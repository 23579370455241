.language-container {
    @include flex-center;
    padding-right: 20px;
    height: 40px;
    border-right: 1px solid $white;
    max-width: max-content !important;

    img {
        width: 20px !important;
        height: 14px !important;
        border: 1px solid rgba(0, 0, 0, 0.1);
        font-size: 13px;
        line-height: 10px;
        border-radius: 2px;
        margin: unset !important;
    }
    .ant-select-selector > .ant-select-selection-item {
        color: white;
        line-height: 37px !important;
    }
}
.flag-container {
    width: 10%;
    height: 30px;

    img {
        font-size: 13px;
        line-height: 10px;
        margin-right: 10px;
        border-radius: 2px;
    }
    .country-name {
        color: $medium-black;
    }
}
.language-select {
    background-color: transparent;
    display: flex !important;
    max-width: max-content !important;
    align-items: center;
    gap: 8px;
    text-transform: uppercase;
    .ant-select-selection-search {
        width: 200%;
        @media screen and (min-width: 1200px) {
            width: 130%;
        }
    }
    .ant-select-selection-item {
        padding-right: 0 !important;
        max-width: max-content !important;
    }
    .ant-select-selector {
        @include regular-font;
        color: $white;
        padding-inline: 0 !important;
        width: fit-content;
        max-width: max-content !important;
    }
    .ant-select-arrow {
        font-size: 14px;
        top: 14px;
        color: $white;
        position: unset;
    }
    &:not(.ant-select-customize-input) .ant-select-selector {
        background-color: transparent !important;
        border: none !important;
    }
    .ant-select-item-option-content {
        .flag-container {
            img {
                width: 20px;
                height: 14px;
                border: 3px;
            }
        }
    }
    .country-name {
        @include regular-font;
        color: $white;
    }
    .flag-container {
        align-items: center;
        img {
            width: 20px;
            height: 14px;
        }
        .country-name {
            color: $medium-black;
        }
    }
    .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
        background-color: transparent !important;
        color: $main-blue !important;
        .ant-select-item-option-content {
            .flag-container {
                .country-name {
                    color: $main-blue;
                }
            }
        }
    }
}
.language-select.active {
    .ant-select-selector {
        color: $medium-black;
        width: fit-content;
    }
}
.language-container.active {
    border-right: 1px solid $light-black;
    .ant-select-arrow {
        svg {
            polyline {
                stroke: $light-black;
            }
        }
    }
}
.language-container.noborder {
    border-right: none;
}
.language-dropdown {
    min-width: max-content !important;
    left: 0px !important;
    @media screen and (min-width: 1200px) {
        margin-left: -25px !important;
    }
}
