.find-car-price-page {
    .valuation-detail {
        @media screen and (max-width: 768px) {
            margin-top: 70px;
        }
        @media screen and (max-width: 1025px) {
            margin-top: 70px;
        }
        @media screen and (min-width: 1025px) {
            margin-top: -50px;
        }

        .top-section {
            max-width: 1440px;
            margin: auto;
            @include flex-center;
            justify-content: center;
            align-items: flex-start;
            gap: 40px 20px;
            @media screen and (max-width: 768px) {
                flex-wrap: wrap;
            }
            .left {
                @media screen and (max-width: 768px) {
                    width: 100%;
                }
                .car-details {
                    .car-deets {
                        @include flex-center;
                        flex-direction: column;
                        justify-content: center;
                        align-items: flex-start;
                        padding: 15px 0;
                        gap: 13px;
                        border-radius: 5px;
                        margin-bottom: 16px;
                        @media screen and (min-width: 768px) {
                            align-items: flex-start;
                        }
                        @media screen and (min-width: 1024px) {
                            padding: 0 0 5px 0;
                        }
                        @media screen and (min-width: 1280px) {
                            padding: 0 0 15px 0;
                        }
                        .car-name {
                            font-family: 'Sofia-Regular';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 20px;
                            line-height: 140%;
                            color: $main-dark-blue;
                        }
                        .car-features-container {
                            width: 100%;
                            .car-features {
                                @include flex-center;
                                flex-wrap: wrap;
                                .features-container {
                                    @include flex-center;
                                    gap: 10px;
                                    padding-right: 10px;
                                    font-family: 'Sofia-Regular';
                                    font-style: normal;
                                    font-weight: 500;
                                    font-size: 14px;
                                    line-height: 14px;
                                    color: #595959;
                                    text-transform: capitalize;
                                    @media screen and (min-width: 768px) {
                                        padding-right: 14px;
                                    }
                                    @media screen and (min-width: 1024px) {
                                        padding-right: 5px;
                                        gap: 5px;
                                    }
                                    @media screen and (min-width: 1440px) {
                                        padding-right: 10px;
                                        gap: 10px;
                                    }
                                    .features-icons {
                                        width: 17px;
                                        height: 17px;
                                    }
                                }
                                .features-container:not(:last-child) {
                                    border-right: 1px solid #b3b3b3;
                                }
                                .features-container:not(:first-child) {
                                    padding-left: 7px;
                                    @media screen and (min-width: 768px) {
                                        padding-left: 10px;
                                    }
                                    @media screen and (min-width: 1024px) {
                                        padding-left: 5px;
                                    }
                                    @media screen and (min-width: 1200px) {
                                        padding-left: 5px;
                                    }
                                }
                            }
                            .card-bottom {
                                @include flex-center;
                                gap: 12px;
                                @media screen and (min-width: 1024px) {
                                    gap: unset;
                                    justify-content: space-between;
                                }
                                .card-cert {
                                    @include flex-center;
                                    img {
                                        width: 24px;
                                        margin-right: 6px;
                                    }

                                    span {
                                        font-family: 'Sofia-Regular';
                                        font-size: 14px;
                                        color: $success;
                                    }
                                }
                                .card-emi-type {
                                    font-family: 'Sofia-Regular';
                                    font-style: normal;
                                    font-weight: 500;
                                    font-size: 14px;
                                    line-height: 14px;
                                    color: $dark-gray;
                                }
                                .dot {
                                    background-color: $grey;
                                }
                            }
                        }
                        .fre {
                            .car-features {
                                .features-container {
                                    gap: 5px;
                                    @media screen and (min-width: 768px) {
                                        padding-right: 10px;
                                    }
                                }
                            }
                        }
                    }
                    .car-image {
                        img {
                            height: 217px;
                            width: 488px;
                            border-radius: 5px;
                            object-fit: cover;
                            @media screen and (max-width: 1024px) {
                                width: 100%;
                            }
                        }
                    }
                }
            }
            .right {
                background-color: #f5f9ff;
                border-radius: 5px;
                padding: 20px;
                align-self: stretch;
                .price {
                    background-color: #fff;
                    padding: 32px 24px;
                    text-align: center;
                    .est-price {
                        font-family: 'Sofia-Bold';
                        font-size: 16px;
                        color: #07348d;
                    }
                    .condition-price {
                        display: flex;
                        justify-content: space-between;
                    }
                    .avg-price-value {
                        font-family: 'Sofia-Bold';
                        font-size: 20px;
                        margin-top: 8px;
                    }
                    .ant-slider-disabled .ant-slider-track {
                        background-color: #008A22 !important;
                    }
                }
                .price-desc {
                    font-size: 13px;
                    font-family: 'Sofia-Regular';
                    color: #595959;
                    margin: 24px 0;
                }
            }
        }
        .market-value {
            @include flex-center;
            flex-direction: column;
            margin-top: 80px;
            .graph-container {
                display: flex;
                gap: 40px;
                margin-top: 16px;
                .graph-item {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: flex-end;
                    font-family: 'Sofia-Regular';
                    text-align: center;
                    p {
                        margin-top: 24px;
                        color: #595959;
                        font-size: 13px;
                    }
                    h1 {
                        color: #000;
                        font-weight: 700;
                        font-size: 20px;
                        @media screen and (max-width: 480px) {
                            font-size: 16px;
                        }
                    }
                    .graph {
                        width: 128px;
                        @media screen and (max-width: 568px) {
                            width: 110px;
                        }
                        @media screen and (max-width: 480px) {
                            width: 80px;
                        }
                    }
                    &:nth-child(1) {
                        .graph {
                            height: 72px;
                            background-color: #ff4637;
                        }
                    }
                    &:nth-child(2) {
                        .graph {
                            height: 104px;
                            background-color: #ff7f37;
                        }
                    }
                    &:nth-child(3) {
                        .graph {
                            height: 152px;
                            background-color: #008A22;
                        }
                    }
                }
            }
        }
        .car-overview {
            margin: 80px 0;
            .overview-container {
                margin-top: 16px;
            }
        }
        .note {
            font-style: italic;
            font-size: 16px;
            text-align: center;
            margin: 20px 0 0 0;
            font-family: 'Sofia-Regular';
            span {
                font-weight: 700;
            }
        }
    }
}
