.edit-profiles {
    .edit-sep {
        margin-inline: 0;
    }
    .edit-title {
        margin: 0;
        text-align: start;
        font-size: 20px !important;
        margin-bottom: 24px;
        @media screen and (min-width: 1024px) {
            margin: 0 auto;
            font-size: 25px !important;
            margin-bottom: 32px;
        }
    }
    .profile-container {
        @include flex-center;
        margin-bottom: 13px;
        .upload-btn {
            height: 40px;
            padding: 8px 16px;
            font-style: normal;
            color: $white;
            font-weight: 400;
            margin-left: 20px;
            font-size: 13px;
            border-radius: 5px;
            cursor: pointer;
            font-family: 'Sofia-Regular';
            @include flex-center;
            justify-content: center;
        }
        .remove-profile-btn {
            height: 40px;
            padding: 8px 15px;
            font-style: normal;
            color: #175adc;
            text-align: center;
            font-weight: 400;
            margin-left: 9px;
            font-size: 13px;
            border-radius: 5px;
            cursor: pointer;
            font-family: 'Sofia-Regular';
            @include flex-center;
            justify-content: center;
        }
        .profile-image {
            width: 60px;
            height: 60px;
            position: relative;
            border-radius: 50%;
            background-color: #e5e5e5;
            img {
                border-radius: inherit;
                justify-content: center;
            }
            @media screen and (min-width: 1024px) {
                width: 100px;
                height: 100px;
                margin-bottom: 0;
            }
            button {
                position: absolute;
                right: 0;
                bottom: 0;
                background: #175adc;
                width: 20px;
                height: 20px;
                border: 2px solid #ffffff;
                border-radius: 60px;
                @include flex-center;
                justify-content: center;
                @media screen and (min-width: 1024px) {
                    width: 32px;
                    height: 32px;
                }
                svg {
                    width: 10px;
                    @media screen and (min-width: 1024px) {
                        width: 20px;
                    }
                }
            }
        }
    }

    .edit-form-container {
        gap: 50px;
        margin-bottom: 80px;
        @media screen and (min-width: 1024px) {
            display: flex;
        }

        .edit-form {
            @media screen and (min-width: 1024px) {
                width: 68%;
            }
            input {
                cursor: text;
            }
            .title {
                font-size: 20px !important;
                margin-bottom: 16px !important;
                text-align: start;
                @media screen and (min-width: 1024px) {
                    margin-bottom: 24px !important;
                }
            }
            .form {
                display: flex;
                flex-direction: column;
                margin-bottom: 40px;
                gap: 24px;
                .profile-input {
                    @include flex-center;
                    gap: 10px;
                    padding: 13px 12px;
                    border: 1px solid $medium-grey;
                    border-radius: 5px;
                    background: $extra-white;
                    height: 50px;
                    .icon {
                        margin-top: 3px;
                    }
                    input {
                        outline: none;
                        background: $extra-white;
                        width: 100%;
                        border: none;
                        @include input-font;
                        text-transform: capitalize;
                    }
                    input[type='email'] {
                        text-transform: unset;
                    }

                    input::placeholder {
                        @include input-placeholder;
                        background-color: $extra-white;
                    }
                }
                .input-background {
                    background-color: $extra-white;
                }
                .profile-number {
                    @include flex-center;
                    gap: 10px;
                    padding: 13px 12px;
                    border: 1px solid #727272;
                    border-radius: 5px;
                    background-color: $extra-white;
                    height: 50px;

                    .icon {
                        margin-top: 3px;
                    }
                    input {
                        outline: none;
                        width: 100%;
                        background: $extra-white;
                        border: none;
                        @include input-font;
                        text-transform: capitalize;
                    }

                    input::placeholder {
                        @include input-placeholder;
                        background-color: $extra-white;
                    }
                    span {
                        background-color: $extra-white;
                    }
                }
                textarea {
                    outline: none;
                    background-color: $extra-white;
                    border: 1px solid $medium-grey;
                    padding: 13px 12px;
                    border-radius: 5px;
                    @include input-font;
                    height: 113px;
                    resize: none;
                }
                textarea::placeholder {
                    @include input-placeholder;
                }
                .form-btn-container {
                    @include flex-center;
                    gap: 15px;
                    .form-btn {
                        @include flex-center;
                        justify-content: center;
                        padding: 8px 16px;
                        gap: 10px;
                        width: 120px;
                        height: 50px;
                        border-radius: 5px;
                        @include regular-font;
                        &.send {
                            background: #175adc;
                            color: #ffffff;
                            &.send:hover {
                                background: #07348d;
                                box-shadow: 0px 5px 10px rgba(23, 90, 220, 0.2);
                            }
                        }
                        &.cancel {
                            border: 1px solid #175adc;
                            color: #175adc;
                            background-color: #ffffff;
                            &.cancel:hover {
                                background: #dee6f7;
                            }
                        }
                    }
                }
            }
            .delete {
                @include flex-center;
                padding: 20px;
                justify-content: space-between;
                background: #fafafa;
                border-radius: 5px;
                p {
                    @include regular-font;
                    color: $medium-black;
                }
                button {
                    @include flex-center;
                    flex-direction: row;
                    justify-content: center;
                    padding: 8px 16px;
                    gap: 10px;
                    width: 86px;
                    height: 35px;
                    background: #ec6d70;
                    border-radius: 5px;
                    @include regular-font;
                    color: #ffffff;
                    &:hover {
                        opacity: 0.8;
                    }
                }
            }
        }
    }
}
.save-modal {
    .ant-modal-content {
        background: #ffffff;
        border-radius: 10px;
        padding: 32px;
        @include flex-center;
        flex-direction: column;
        justify-content: center;
        padding: 32px;
        .ant-modal-body {
            width: 100%;
            .title {
                text-align: center;
                margin-bottom: 8px;
            }
            .subtitle {
                text-align: center;
                margin-bottom: 43px;
            }
            .btn-container {
                @include flex-center;
                justify-content: space-between;
                gap: 24px;
                button {
                    @include regular-font;
                    min-height: 50px;
                    width: 100%;
                    border-radius: 5px;
                }
                .send-btn {
                    border: 1px solid $main-blue;
                    color: $white;
                    background-color: $main-blue;
                    @include flex-center;
                    gap: 10px;
                    justify-content: center;
                    &:hover {
                        background-color: #07348d;
                    }
                }
                .cancel {
                    @include regular-font;
                    color: $main-blue;
                    background-color: $white;
                    border: 1px solid $main-blue;

                    &:hover {
                        background-color: #dee6f7;
                    }
                }
            }
        }

        .ant-modal-footer {
            display: none;
        }
    }
}
.save-changes-modal {
    .ant-modal {
        background: #ffffff;
        border-radius: 10px;
        padding: 32px;
        width: 430px !important;
        .ant-modal-content {
            padding: 32px !important;
            border-radius: 10px;
            .ant-modal-header {
                border-radius: 10px;
                border: none;
                padding: 0;
                margin-bottom: 8px;
                .ant-modal-title {
                    font-family: 'Sofia-Bold';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 25px;
                    line-height: 140%;
                    text-align: center;
                    color: $main-dark-blue;
                    border: none;
                }
            }
            .ant-modal-body {
                padding: 0;
                .title {
                    font-size: 25px !important;
                }
                .subtitle {
                    padding: 0;
                    text-align: center;
                }
                .done-icon {
                    width: 105px;
                    height: 90px;
                    margin: auto;
                    margin-top: 36px;
                    margin-bottom: 36px;
                }
            }
        }
    }
    @media screen and (min-width: 768px) {
        width: 430px !important;
    }
    .ant-modal-content {
        padding: 32px !important;
        border-radius: 10px;
        .ant-modal-header {
            border-radius: 10px;
            border: none;
            padding: 0;
            margin-bottom: 8px;
            .ant-modal-title {
                font-family: 'Sofia-Bold';
                font-style: normal;
                font-weight: 700;
                font-size: 25px;
                line-height: 140%;
                text-align: center;
                color: $main-dark-blue;
                border: none;
            }
        }
        .ant-modal-body {
            padding: 0;

            .subtitle {
                padding: 0;
                text-align: center;
            }
            .done-icon {
                width: 105px;
                height: 90px;
                margin: auto;
                margin-top: 36px;
                margin-bottom: 36px;
            }
            .modal-btn-container {
                @include flex-center;
                justify-content: space-between;
                button {
                    @include flex-center;
                    flex-direction: row;
                    justify-content: center;
                    padding: 8px 16px;
                    gap: 10px;
                    border-radius: 5px;
                    width: 171px;
                    height: 50px;
                    border-radius: 5px;
                    @include regular-font;
                    width: calc((100% - 24px) / 2);
                    text-shadow: none !important;
                }
                .explore {
                    background: #175adc;
                    border: none;
                    color: #ffffff;
                    width: 171px;
                    &.explore:hover {
                        background: #07348d;
                        box-shadow: 0px 5px 10px rgba(23, 90, 220, 0.2);
                    }
                }
                .back-to-home {
                    border: 1px solid #175adc;
                    color: $main-blue;
                    background-color: transparent;
                    &.back-to-home:hover {
                        background: #dee6f7;
                    }
                }
            }
            .no-car {
                justify-content: center;
            }
        }
        .ant-modal-footer {
            display: none;
        }
    }
}
.delete-modal {
    .ant-modal {
        background: #ffffff;
        border-radius: 10px;
        padding: 32px;
        width: 430px !important;
        .ant-modal-content {
            padding: 32px !important;
            border-radius: 10px;
            .ant-modal-header {
                border-radius: 10px;
                border: none;
                padding: 0;
                margin-bottom: 8px;
                .ant-modal-title {
                    font-family: 'Sofia-Bold';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 25px;
                    line-height: 140%;
                    text-align: center;
                    color: $main-dark-blue;
                    border: none;
                }
            }
            .ant-modal-body {
                padding: 0;
                .title {
                    font-size: 25px !important;
                }
                .subtitle {
                    padding: 0;
                    text-align: center;
                }
                .done-icon {
                    width: 105px;
                    height: 90px;
                    margin: auto;
                    margin-top: 36px;
                    margin-bottom: 36px;
                }
            }
            .ant-modal-footer {
                border: none;
                display: flex;
                flex-direction: row-reverse;
                justify-content: space-between;
                width: 100%;
                gap: 24px;
                .ant-btn {
                    @include flex-center;
                    flex-direction: row;
                    justify-content: center;
                    padding: 8px 16px;
                    gap: 10px;
                    border-radius: 5px;
                    width: 171px;
                    height: 50px;
                    border-radius: 5px;
                    @include regular-font;
                    width: calc((100% - 24px) / 2);
                    text-shadow: none !important;
                    &.ant-btn-default {
                        border: 1px solid #175adc;
                        color: #175adc;
                    }
                }
            }
        }
    }
    .subtitle {
        width: 100% !important;
    }
    &.logout {
        .ant-modal-content {
            .ant-modal-header {
                .ant-modal-title {
                    margin-bottom: 24px;
                }
            }
            .ant-modal-body {
                .logout-btn {
                    cursor: pointer;
                    background-color: #ec6d70;
                    border: none;
                    @include regular-font;
                    color: #ffffff;
                    &:hover {
                        opacity: 0.8;
                    }
                }
            }
        }
    }
    .ant-modal-content {
        padding: 32px !important;
        border-radius: 10px;
        .ant-modal-header {
            border-radius: 10px;
            border: none;
            padding: 0;
            margin-bottom: 8px;
            .ant-modal-title {
                font-family: 'Sofia-Bold';
                font-style: normal;
                font-weight: 700;
                font-size: 25px;
                line-height: 140%;
                text-align: center;
                color: $main-dark-blue;
                border: none;
                width: 70%;
                margin: 0 auto;
            }
        }
        .ant-modal-body {
            padding: 0;
            .subtitle {
                padding: 0;
                text-align: center;
                width: 81%;
                margin: 0 auto;
                margin-bottom: 32px;
            }
            .modal-btn-container {
                @include flex-center;
                gap: 24px;
                button {
                    @include flex-center;
                    flex-direction: row;
                    justify-content: center;
                    padding: 8px 16px;
                    gap: 10px;
                    border-radius: 5px;
                    width: 171px;
                    height: 50px;
                    border-radius: 5px;
                    @include regular-font;
                    width: calc((100% - 24px) / 2);
                    text-shadow: none !important;
                }

                .cancel {
                    cursor: pointer;
                    border: 1px solid #175adc;
                    color: $main-blue;
                    background-color: #ffffff;
                    &:hover {
                        background-color: #dee6f7;
                    }
                }
                .delete {
                    background: #ec6d70;
                    border: none;
                    @include regular-font;
                    color: #ffffff;
                    &:hover {
                        opacity: 0.8;
                    }
                }
                .logout-btn {
                    cursor: pointer;
                    background-color: #ec6d70;
                    border: none;
                    @include regular-font;
                    color: $white;
                    padding: 8px 16px;
                    border-radius: 5px;
                    &:disabled {
                        background-color: $dark-gray;
                        cursor: no-drop;
                        border: 1px solid $dark-gray;
                    }
                    &:hover {
                        opacity: 0.8;
                    }
                }
            }
        }
    }
}
.delete-changes-modal {
    .ant-modal {
        background-color: $white;
        border-radius: 10px;
        padding: 32px;
        width: 430px !important;
        .ant-modal-content {
            padding: 32px !important;
            border-radius: 10px;
            .ant-modal-header {
                border-radius: 10px;
                border: none;
                padding: 0;
                margin-bottom: 8px;
                .ant-modal-title {
                    font-family: 'Sofia-Bold';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 25px;
                    line-height: 140%;
                    text-align: center;
                    color: $main-dark-blue;
                    border: none;
                }
            }
            .ant-modal-body {
                padding: 0;
                .title {
                    font-size: 25px !important;
                }
                .subtitle {
                    padding: 0;
                    text-align: center;
                }
                .done-icon {
                    width: 105px;
                    height: 90px;
                    margin: auto;
                    margin-top: 36px;
                    margin-bottom: 36px;
                }
            }
            .ant-modal-footer {
                border: none;
                display: flex;
                flex-direction: row-reverse;
                justify-content: space-between;
                width: 100%;
                gap: 24px;
                .ant-btn {
                    @include flex-center;
                    justify-content: center;
                    flex-direction: row;
                    padding: 8px 16px;
                    gap: 10px;
                    border-radius: 5px;
                    width: 171px;
                    height: 50px;
                    border-radius: 5px;
                    @include regular-font;
                    width: calc((100% - 24px) / 2);
                    text-shadow: none !important;
                    &.ant-btn-default {
                        border: 1px solid #175adc;
                        color: #175adc;
                    }
                }
            }
        }
    }
    .ant-modal-content {
        padding: 32px !important;
        border-radius: 10px;
        .ant-modal-header {
            border-radius: 10px;
            border: none;
            padding: 0;
            margin-bottom: 8px;
            .ant-modal-title {
                font-family: 'Sofia-Bold';
                font-style: normal;
                font-weight: 700;
                font-size: 25px;
                line-height: 140%;
                text-align: center;
                color: $main-dark-blue;
                border: none;
            }
        }
        .ant-modal-body {
            padding: 0;
            .subtitle {
                padding: 0;
                text-align: center;
                width: 70%;
                margin: 0 auto;
                margin-bottom: 32px;
            }
        }
        .modal-btn-container {
            justify-content: center !important;
        }
        .ant-modal-footer {
            padding: 0;
            justify-content: center;
            text-align: center;
            .ant-btn {
                &.ant-btn-default {
                    display: none;
                }
                &.ant-btn-primary {
                    background: $main-blue;
                    border: none;
                    width: 200px;
                    &.ant-btn-primary:hover {
                        background: #07348d;
                        box-shadow: 0px 5px 10px rgba(23, 90, 220, 0.2);
                    }
                }
            }
            .ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
                margin: 0 !important;
            }
            .ant-btn:focus {
                outline: none;
                box-shadow: none;
            }
        }
    }
}
