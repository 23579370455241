.search-filter {
    margin: auto;
    padding-top: 14px !important;
    @media screen and (min-width: 768px) {
        padding-top: 50px !important;
    }
    .search-filter-top {
        border: 1px solid $medium-grey;
        border-radius: 5px;
        margin-bottom: 20px;
        height: 50px;
        padding: 13px;
        background-color: $extra-white;
        @media screen and (min-width: 768px) {
            padding: 12px 24px;
            height: 70px;
        }
        form {
            @include flex-center;
            justify-content: space-between;
            padding: 2px;
            border-radius: 5px;
            width: 100%;
            height: 100%;
            .input-container {
                display: flex;
                gap: 7px;
                flex: 8;
                @media screen and (min-width: 768px) {
                    gap: 14px;
                }
                .searchicon {
                    width: 20px;
                    height: 15px;
                    img {
                        object-fit: contain;
                    }
                    @media screen and (min-width: 768px) {
                        width: 21px;
                        height: 20px;
                    }
                }
                input[type='search'] {
                    border: none;
                    background: transparent;
                    margin: 0;
                    font-size: 14px;
                    color: inherit;
                    border: 1px solid transparent;
                    border-radius: inherit;
                    width: 100%;
                    border-right: 1px solid $dark-gray;
                    margin-right: 7px;
                    font-family: 'Sofia-Regular';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 140%;
                    color: $dark-gray;
                    cursor: auto;
                    @media screen and (min-width: 768px) {
                        margin-right: 24px;
                        font-size: 16px;
                    }
                }

                input[type='search']::placeholder {
                    font-family: 'Sofia-Regular';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 140%;
                    @include flex-center;
                    color: #727272;
                    @media screen and (min-width: 768px) {
                        font-size: 16px;
                    }
                }
            }
            .more-filter {
                flex: 1.2 1;
                font-family: 'Sofia-Regular';
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 140%;
                @include flex-center;
                justify-content: space-between;
                color: $dark-gray;
                cursor: pointer;
                @media screen and (min-width: 768px) {
                    font-size: 16px;
                }
            }
        }
    }
    .search-filter-bottom {
        display: none;

        @media screen and (min-width: 1024px) {
            display: block;
        }
        .show-initially {
            @include flex-center;
            flex-wrap: wrap;
            gap: 20px;

            .selects-container {
                @media screen and (min-width: 1024px) {
                    width: calc((100% - 80px) / 5);
                }
            }
        }
        .more-filters {
            display: flex;
            flex-wrap: wrap;
            column-gap: 20px;
            margin-top: 0px;
            row-gap: 10px;
            margin-top: 10px;
            .selects-container {
                font-family: 'Sofia-Regular';
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 140%;
                color: #727272;
                cursor: pointer;
                @media screen and (min-width: 768px) {
                    font-size: 16px;
                }
                @media screen and (min-width: 1024px) {
                    width: calc((100% - 40px) / 3);
                }
            }
        }
    }

    .show-filters {
        display: block;
    }
}
.input-month {
    display: flex;
    justify-content: space-between;
    @include regular-font;
    color: #727272;
    padding: 14px 20px;

    span {
        user-select: none;
    }
    .input-prefix {
        margin-right: 0.3rem;
        @include input-font;
        color: $medium-grey;
    }
    .ant-input {
        background-color: $extra-white;
    }
    input::placeholder {
        @include input-font;
        color: $light-medium-b2;
    }
}
.ant-slider-handle {
    :focus {
        box-shadow: none;
    }
}
.range-filter-container {
    position: relative;
    padding: 0;
    margin-top: 0;
    .error-message {
        padding-inline: 16px;
    }
    .range-filter {
        padding: 24px 0 0;
        z-index: 100;
        position: absolute;
        width: 400px;
        background: #ffffff;
        border-width: 0px 1px 1px 1px;
        border-style: solid;
        border-color: #e0e0e0;
        box-shadow: 0px -2px 20px rgba(0, 0, 0, 0.05);
        border-radius: 0px 0px 5px 5px;
        &.right {
            right: 0px;
        }
        &.left {
            left: 0;
        }
        .slider {
            padding-inline: 16px;
            .right {
                float: right;
            }
            .ant-slider-track {
                background-color: $main-dark-blue;
                height: 9px;
            }
            .ant-slider-handle {
                width: 19px;
                height: 19px;
                margin-top: -7px;
                border: solid 2px #fff;
                box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
            }
        }
        .mid-section {
            @include flex-center;
            justify-content: space-between;
            position: relative;
            padding-inline: 16px;
            .separator {
                width: 20px;
                position: absolute;
                border: 0.1px solid #b3b3b3;
                align-self: center;
                top: 65%;
                left: 47%;
            }
            .input-section {
                @include flex-center;
                width: 160px;
                height: 48px;
                position: relative;
                padding: 9px;
                justify-content: space-between;
                background: #f5f8ff;
                border: 1px solid #b3b3b3;
                border-radius: 5px;
                img {
                    width: 20px;
                    height: 20px;
                }
                input {
                    background: transparent;
                    height: 100%;
                    width: 100%;
                    font-size: 18px;

                    padding: 0 0 0 0.5em;
                }

                span {
                    margin: 0;
                    position: absolute;
                    top: 10;
                    right: 30px;
                }
            }
        }

        .footer {
            @include flex-center;
            @include regular-font;
            justify-content: space-between;
            flex-direction: row;
            border-top: 1px solid #e0e0e0;
            border-color: #e0e0e0;
            box-shadow: 0px -2px 20px rgba(0, 0, 0, 0.05);
            margin-top: 20px;
            padding: 24px 16px;
            border-radius: 0px 0px 5px 5px;

            .footer-cancel {
                @include flex-center;
                flex-direction: row;
                justify-content: center;
                padding: 0.3em 1em;
                width: 176.5px;
                height: 45px;
                cursor: pointer;
                background: #ffffff;
                border: 1px solid #454545;
                border-radius: 5px;
            }
            .footer-save {
                @include flex-center;
                cursor: pointer;
                flex-direction: row;
                justify-content: center;
                padding: 0.3em 1em;
                color: #ffff;
                width: 176.5px;
                height: 45px;
                background: #175adc;
                border: 1px solid #e0e0e0;
                border-radius: 5px;
                &:disabled {
                    background-color: $medium-grey;
                }
            }
        }
    }
}
.show-range {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-content: center;
    font-size: 15px;
    overflow: hidden;
    .reset-icon {
        margin-left: 3px;
        align-self: center;
        text-align: center;
        padding: 2px;
        display: inline-block;
        background-color: currentColor;
        opacity: 0.7;
        @include flex-center;
        justify-content: center;
        margin-top: -3px;
        border-radius: 50%;
        transition: 0.2s all ease-in;
    }
}
.search-filter-modal {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-block: 20px;
    .filter-sep {
        margin: unset;
    }
    .title {
        text-align: left;
        margin-bottom: 0;
    }
    .more-filters {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
}
