.ants-modal {
    background: #ffffff;
    border-radius: 10px;
    padding: 32px;
    width: 430px !important;

    .ant-modal-content {
        padding: 32px !important;
        border-radius: 10px;
        .ant-modal-header {
            border-radius: 10px;
            border: none;
            padding: 0;
            margin-bottom: 8px;
            .ant-modal-title {
                font-family: 'Sofia-Bold';
                font-style: normal;
                font-weight: 700;
                font-size: 25px;
                line-height: 140%;
                text-align: center;
                color: $main-dark-blue;
                border: none;
            }
        }
        .ant-modal-body {
            padding: 0;
            .title {
                font-size: 25px !important;
            }
            .subtitle {
                padding: 0;
                text-align: center;
            }
            .done-icon {
                width: 105px;
                height: 90px;
                margin: auto;
                margin-top: 36px;
                margin-bottom: 36px;
            }
            .get-call-back {
                @include flex-center;
                justify-content: center;
                button {
                    @include flex-center;
                    flex-direction: row;
                    justify-content: center;
                    padding: 8px 16px;
                    gap: 10px;
                    border-radius: 5px;
                    width: 171px;
                    height: 50px;
                    border-radius: 5px;
                    @include regular-font;
                    width: calc((100% - 24px) / 2);
                    text-shadow: none !important;
                }
                .explore {
                    background: #175adc;
                    border: none;
                    color: #ffffff;
                    &.explore:hover {
                        background: #07348d;
                        box-shadow: 0px 5px 10px rgba(23, 90, 220, 0.2);
                    }
                }
                .back-to-home {
                    border: 1px solid #175adc;
                    color: $main-blue;
                    background-color: transparent;
                    &.back-to-home:hover {
                        background: #dee6f7;
                    }
                }
            }
        }
        .ant-modal-footer {
            border: none;
            display: flex;
            display: none;
            flex-direction: row-reverse;
            justify-content: space-between;
            width: 100%;
            gap: 24px;
            padding: 0;
            .ant-btn {
                @include flex-center;
                flex-direction: row;
                justify-content: center;
                padding: 8px 16px;
                gap: 10px;
                border-radius: 5px;
                width: 171px;
                height: 50px;
                border-radius: 5px;
                @include regular-font;
                width: calc((100% - 24px) / 2);
                text-shadow: none !important;
                &.ant-btn-default {
                    border: 1px solid #175adc;
                    color: #175adc;
                }
            }
        }
    }
    .ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
        margin-left: 0 !important;
    }
}

.modal-wrapper {
    width: 946px !important ;
    border-radius: 5px;
    @media (min-width: 601px) and (max-width: 720px) {
        width: 500px !important;
    }
    @media (max-width: 600px) {
        width: calc(100vw - 30px) !important;
        .ant-modal-close {
            display: none;
        }
    }
    .ant-modal-content {
        border-radius: 5px;
        .ant-modal-body {
            padding: 0px;
            height: inherit;
        }
    }
}
.modal-wrapper-medium {
    max-width: 440px !important ;
    @media (max-width: 600px) {
        width: calc(100vw - 30px) !important;
    }
    .ant-modal-content {
        border-radius: 5px;
        .ant-modal-body {
            padding: 0px;
            height: inherit;
        }
    }
}
.modal-wrapper-small {
    max-width: 430px !important ;
    .ant-modal-content {
        border-radius: 5px;
        .ant-modal-body {
            padding: 0px;
            height: inherit;
        }
    }
}

.modal-content-wrapper {
    display: flex;
    height: inherit;
    .modal-left-content-wrapper {
        border-radius: 5px 0px 0px 5px;
        background-color: $main-blue;
        width: 426px;
        padding: 24px;
        display: flex;
        @media (min-width: 860px) and (max-width: 900px) {
            width: 380px;
        }
        @media (min-width: 720px) and (max-width: 859px) {
            width: 320px;
        }
        @media (max-width: 719px) {
            display: none;
        }
        flex-direction: column;
        .modal-top-image {
            height: 61px;
            width: 61px;
            position: absolute;
        }
        .modal-left-content {
            margin: auto;
            margin-top: 80px;
        }
    }

    .modal-right-content-wrapper {
        border-radius: 0px 5px 5px 0px;
        display: flex;
        flex-direction: column;
        flex: 1;
        background-color: $white;
        padding: 57px 80px 49px;
        margin: auto;
        @media (min-width: 720px) and (max-width: 980px) {
            padding: 47px 40px;
        }
        @media (max-width: 719px) {
            padding: 32px 24px;
            border-radius: 5px;
        }
        .modal-right-content {
            flex-grow: 1;
            text-align: left;
            .right-header {
                font-family: Sofia-Bold;
                font-size: 25px;
                font-weight: 700;
                line-height: 1.4;
                @media (max-width: 580px) {
                    font-size: 20px;
                }
            }
        }
        .modal-right-content-center {
            text-align: center;
        }
    }

    .right-padding-40 {
        padding: 38px 40px 40px;
        @media (max-width: 719px) {
            padding: 32px 24px;
            border-radius: 5px;
        }
    }

    .right-padding-80 {
        padding: 80px;
        @media (min-width: 720px) and (max-width: 980px) {
            padding: 68px 24px;
            border-radius: 5px;
        }
        @media (max-width: 719px) {
            padding: 32px 24px;
            border-radius: 5px;
        }
    }

    .border-radius-fix {
        border-radius: 5px;
    }

    .modal-right-content-wrapper-signin {
        border-radius: 0px 5px 5px 0px;
        display: flex;
        flex-direction: column;
        flex: 1;
        background-color: $white;
        padding: 108px 80px;
        margin: auto;
        .modal-right-content {
            flex-grow: 1;
            text-align: left;
            .right-header {
                font-family: Sofia-Bold;
                font-size: 25px;
                font-weight: 700;
                line-height: 1.4;
            }
        }
        .modal-right-content-center {
            text-align: center;
        }
    }
}

.modal-sec-seperator {
    width: 80px;
    margin-bottom: 8px;
    @media (max-width: 980px) {
        margin: 0px 0px 8px 0px;
    }
}

.bottom-margin {
    margin-bottom: 40px;
}

.left-data-wrapper {
    display: flex;
    padding-left: 36px;

    .modal-icon {
        @include flex-center;
        padding: 5px;
        border-radius: 5px;
        height: 100%;
        background-color: $white;
        height: 40px;
        width: 40px !important;
        min-width: max-content;
        img {
            filter: blur(0);
        }
    }
    span {
        display: block;
    }
    .left-content {
        margin-left: 16px;
        .left-content-header {
            font-family: Sofia-Bold;
            font-size: 16px;
            line-height: 1.4;
            margin-bottom: 6px;
            font-weight: 700;
            color: $white;
        }
        .left-content-body {
            @include regular-font;
            color: $white;
        }
    }
}
.callback-modal {
    width: 90% !important;
    max-width: 590px !important;
    margin-top: 40px !important;
    .error-label {
        position: absolute;
    }
    @media screen and (min-width: 768px) {
        width: 590px;
    }

    @media (max-width: 980px) {
        padding: 1rem;
        margin-top: 30px !important;
        .cert-submit {
            display: block;
        }
        .bottom-btn {
            margin-top: 1rem;
            width: 100%;
            max-width: 100%;
        }
    }

    .getacallback-header {
        font-family: Sofia-Bold;
        font-size: 20px;
        font-weight: 700;
        line-height: 28px;
        letter-spacing: 0em;
    }

    .callback-title {
        //styleName: Regular/16;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
    }
    .phone-input {
        margin-top: 1.3rem;
        label {
            padding-left: 1em;
            margin-right: 1rem;
        }
        input {
            width: 100%;
            height: 100%;
            // @include input-font;
            color: $dark-gray;
            background-color: $extra-white;
            @media screen and (min-width: 768px) {
                font-size: 16px;
            }
        }
        input::placeholder {
            // @include input-font;
            color: $light-medium-b2;
        }
    }
    .phone-number {
        margin: 1.3rem 0;
    }
    .callback-footer {
        margin-top: 0.2rem;
        .bottom-btn {
            &:disabled {
                background-color: $dark-gray;
                cursor: no-drop;
                border: 1px solid $dark-gray;
            }
        }
    }
}
