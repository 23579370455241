.card {
    cursor: pointer;
    position: relative;
    margin: auto;
    &.border {
        border: 1px solid #e0e0e0;
        border-radius: 5px;
        background-color: $white;
    }

    .card-imageContainer {
        width: 100%;
        height: 199px;
        border-radius: 5px 5px 0 0;
        overflow: hidden;
        img {
            border-radius: 5px 5px 0 0;
            transition: transform 0.5s ease-out;
        }
    }

    .card-brand-logo {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 10px;
        gap: 10px;

        position: absolute;
        height: 60px;
        width: 60px;
        right: 4.41%;
        top: calc(50% - 60px / 2);

        background-color: $white;
        border: 1px solid $grey;
        border-radius: 50%;
        z-index: 2;
    }

    .card-status {
        font-family: 'Sofia-Regular';
        text-transform: uppercase;
        margin: 10px;
        position: absolute;
        top: 0;
        padding: 4px 8px;
        border-radius: 2px;
        z-index: 2;
        display: flex;
        align-items: center;
        gap: 4px;

        & > span {
            font-style: normal;
            font-family: 'Sofia-Bold';
            font-weight: 700;
            font-size: 10px;
            color: #ffffff;
        }

        &.new-tag {
            background: #20B038;
        }
        &.sold-tag {            
            background: #FF7D44;
            right: 0;
            > span {
                color: #000000;
            }
        }
        &.discount-tag {
            background: #DC1717;
            right: 0;
        }
    }

    .card-contents {
        background-color: $white;
        padding: 24px 16px 12px;
        border-radius: 5px;
        transform: translate(0, -5px);
        @media screen and (min-width: 800px) and (max-width: 1199px) {
            padding: 24px 6px 12px;
        }
        .card-model {
            display: block;
            height: 20px;
            @include regular-font;
            color: #595959;
            margin-bottom: 8px;
        }
        .card-price-buttons {
            @include flex-center;
            justify-content: space-between;
            margin-bottom: 16px;
            .card-price {
                font-family: 'Sofia-Bold';
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                line-height: 20px;
                color: #175adc;
            }
            .card-old-price {
                font-family: 'Sofia-Bold';
                color: #727272;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                text-decoration: line-through solid 2px #727272;
                text-decoration-line: line-through;
                text-decoration-color: #727272;
                text-decoration-thickness: 2px;
                margin-left: 8px;
            }
            .card-buttons {
                @include flex-center;
                gap: 20px;
                .active {
                    animation: like 0.2s 1;
                    fill: red;
                    stroke: none;
                }
                @keyframes like {
                    0% {
                        transform: scale(1);
                    }
                    90% {
                        transform: scale(1.2);
                    }
                    100% {
                        transform: scale(1.1);
                    }
                }
            }
        }
        .card-features {
            @include flex-center;
            flex-wrap: wrap;
            margin-bottom: 16px;
            width: 100%;
            .features-container {
                padding-right: 5px;
                @include flex-center;
                gap: 5px;
                font-family: 'Sofia-Regular';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 14px;
                color: #595959;
                text-transform: capitalize;

                @media screen and (min-width: 1440px) {
                    padding-right: 10px;
                    gap: 10px;
                }

                img {
                    width: 20px;
                    @media screen and (min-width: 1024px) and (max-width: 1200px) {
                        width: 16px;
                    }
                }

                .features-icons {
                    width: 17px;
                    height: 17px;
                }
            }
            .features-container:not(:last-child) {
                border-right: 1px solid #b3b3b3;
            }
            .features-container:not(:first-child) {
                padding-left: 5px;
                @media screen and (min-width: 1440px) {
                    padding-left: 10px;
                }
            }
        }
        .card-bottom {
            .space-container {
                gap: 0 !important;
                width: 100%;
                justify-content: space-between;
            }
            .card-cert {
                @include flex-center;
                img {
                    width: 24px;
                    margin-right: 6px;
                }

                span {
                    font-family: 'Sofia-Regular';
                    font-size: 14px;
                    color: $success;
                }
            }
            .dot {
                background-color: $grey;
            }
        }
    }
}
.card-holder {
    &:hover {
        border-radius: 7px 7px 5px 5px;
        background-color: $main-blue;
        box-shadow: 0px 10px 20px rgba(23, 90, 220, 0.1);
    }
}
.pointer {
    cursor: pointer;
}

.cardArrow {
    border-radius: 3px;
    height: 24px;
    width: 24px;
    padding: 3px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    background-color: #eeeeeecc;
    svg {
      height: 18px;
      width: 18px;
    }
    &__left {
      left: 8px;
    }
    &__right {
      right: 8px;
    }
}
