.need-help-container {
  background-color: #FFFFFF;
  .wrapper {
    padding-block: 60px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #F2F2F2;

    @media screen and (max-width: 1024px) {
      flex-direction: column;
      gap: 40px;
    }
    .segment-info {
      h2.title {
        font-size: 25px;
        line-height: 35px;
        font-weight: 800;
        font-family: 'Sofia-Bold';
      }
    }
  
    .contact-info {
      display: flex;
      gap: 40px;
      @media screen and (max-width: 767px) {
        flex-direction: column;
        gap: 20px;
        width: 100%;
      }
  
      .info-container {
        width: 350px;
        height: 82px;
        padding: 16px;
        background-color: #FAFAFA;
        border: 1px solid #F2F2F2;
        border-radius: 5px;
        display: flex;
        align-items: center;
        gap: 8px;
        @media screen and (max-width: 768px) {
          width: 100%;
        }

        .icon {
          height: 48px;
          width: 48px;
          background-color: #175ADC;
          border-radius: 50%;
          color: #FFFFFF;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .label {
          font-family: 'Sofia-Bold';
          font-size: 20px;
          font-weight: 700;
          line-height: 28px;
        }

        .value {
          font-family: 'Sofia-Regular';
          font-size: 16px;
          font-weight: 400;
          line-height: 22.4px;
          color: #8b8b8b;
        }
      }
    }
  }
}